import React from "react";
import Layout from "./src/components/layout";
import ContextProvider from "./src/components/Appointment/context";
import "swiper/css";
import "aos/dist/aos.css";
import "react-modal-video/scss/modal-video.scss";
import "./src/assets/scss/style.scss";

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      <ContextProvider>{element}</ContextProvider>
    </Layout>
  );
};
