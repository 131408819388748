import React, { useEffect, useState } from "react";
// import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"; when payment active
import Button from "../Button";
import Input from "./Input";
import dayjs from "dayjs";
// import axios from "axios"; when payment active

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

// const CARD_ELEMENT_OPTIONS = {
//   iconStyle: "solid",
//   hidePostalCode: true,
//   style: {
//     base: {
//       iconColor: "#1292ee",
//       color: "#748494",
//       fontSize: "16px",
//       fontFamily: '"Open Sans", sans-serif',
//       fontSmoothing: "antialiased",
//       backgroundColor: "transparent",

//       "::placeholder": {
//         color: "#748494",
//       },
//     },
//     invalid: {
//       color: "#e5424d",
//       ":focus": {
//         color: "#303238",
//       },
//     },
//   },
// };

export default function Step2({
  onClick,
  state,
  handleChange,
  saving,
  message,
  // setMessage,
  selectedType,
  // setSaving,
  // saveAppointment,
}) {
  // const stripe = useStripe();
  // const elements = useElements();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    // if (state.appointmentTypeID) {
    //   const filter = appointments.filter(
    //     (i) => i.id === Number(state.appointmentTypeID)
    //   );
    //   setTypeName(filter[0].name);
    // }
    if (state.datetime) {
      setDate(dayjs(state.datetime).tz(state.timezone).format("MMM D"));
      setTime(dayjs(state.datetime).tz(state.timezone).format("hh:mm A"));
    }
  }, [state]);

  //  When payment active

  // const handleSubmit = async () => {
  //   setMessage("");
  //   if (!onClick()) return;
  //   if (parseInt(selectedType?.price) === 0) {
  //     saveAppointment();
  //     return;
  //   }
  //   setSaving(true);

  //   if (!stripe || !elements) {
  //     // Stripe.js hasn't yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }

  //   const response = await axios.post(
  //     "https://leads.civsav.com/stripe/secret",
  //     {
  //       price: parseFloat(selectedType.price) * 100,
  //     }
  //   );
  //   if (response.status === 200) {
  //     const { client_secret: clientSecret } = response.data;

  //     const result = await stripe.confirmCardPayment(clientSecret, {
  //       payment_method: {
  //         card: elements.getElement(CardElement),
  //         billing_details: {
  //           name: `${state.firstName} ${state.lastName}`,
  //           email: state.email,
  //         },
  //       },
  //     });

  //     if (result.error) {
  //       setMessage(result.error.message);
  //       setSaving(false);
  //     } else {
  //       // The payment has been processed!
  //       if (result.paymentIntent.status === "succeeded") {
  //         saveAppointment();
  //       }
  //     }
  //   } else {
  //     setMessage("Payment didn't charge");
  //     setSaving(false);
  //   }
  // };

  return (
    <>
      <h1 className="common-heading">{selectedType?.name}</h1>
      <p>
        {selectedType?.duration} Minutes - {date}th - {time}
      </p>
      <div className="ap-form">
        <div className="inline-flex">
          <Input
            type={"text"}
            placeholder="First Name"
            name="firstName"
            onChange={handleChange}
          />
          <Input
            type={"text"}
            placeholder="Last Name"
            name="lastName"
            onChange={handleChange}
          />
        </div>
        <Input
          type={"email"}
          placeholder="Email Address"
          name={"email"}
          onChange={handleChange}
        />
        <Input
          type={"text"}
          placeholder="Phone Number"
          name="phone"
          onChange={handleChange}
        />
        <Input
          type={"text"}
          placeholder="Notes (Optional)"
          name="notes"
          onChange={handleChange}
        />

        {/* {parseInt(selectedType?.price) !== 0 && (
          <div className="py-2 bg-">
            <CardElement className="ap-input" options={CARD_ELEMENT_OPTIONS} />
          </div>
        )} */}

        {message && <p className="note-text">{message}</p>}

        <Button
          text={saving ? "Saving..." : "Complete Appointment"}
          // disable={saving || !stripe} when payment active
          disable={saving}
          onClick={() => onClick(100)}
          // onClick={handleSubmit}
        />
      </div>
    </>
  );
}
