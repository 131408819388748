import React, { createContext, useContext, useState } from "react";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import Appointment from "./index";

// const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

const contextDefaultValues = {
  scheduler: false,
  toggleScheduler: () => {
    return;
  },
};

const SchedulerContext = createContext(contextDefaultValues);

export function useSchedulerContext() {
  return useContext(SchedulerContext);
}

export default function ContextProvider({ children }) {
  const [scheduler, setScheduler] = useState(false);

  const toggleScheduler = () => {
    setScheduler(!scheduler);
  };

  const value = {
    scheduler,
    toggleScheduler,
  };
  return (
    <SchedulerContext.Provider value={value}>
      {/* <Elements stripe={stripePromise}> */}
      {children}
      {scheduler && <Appointment closeModal={toggleScheduler} />}
      {/* </Elements> */}
    </SchedulerContext.Provider>
  );
}
