import React from "react";
import "./style.css";
import StepsBar from "./StepsBar";
import useAppointment from "./useAppointment";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Loader from "./Loader";

export default function Appointment({ closeModal }) {
  const {
    progress,
    state,
    saving,
    appointments,
    loader,
    selectedType,
    message,
    meeting,
    setState,
    handleProgress,
    handleChange,
    setMessage,
    setSaving,
    saveAppointment,
  } = useAppointment();

  return (
    <div className="appointment scrollbar-hide" onClick={closeModal}>
      <div
        className="ap-inner scrollbar-hide"
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <span className="ap-close" role="button" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </span>
          <div className="ap-heading">
            <h1 className="text-gradient">Schedule a Time</h1>
            <p className="common-heading">
              Please choose an available time to chat with TJ.
            </p>
          </div>
          <StepsBar handleProgress={handleProgress} progress={progress} />

          <div className="options">
            {loader ? (
              <Loader />
            ) : appointments.length === 0 ? (
              <div className="ap-empty">There is no appointment type found</div>
            ) : (
              <>
                <div className="choose" id="step1">
                  <Step1
                    state={state}
                    handleChange={handleChange}
                    onClick={handleProgress}
                    setState={setState}
                    selectedType={selectedType}
                    appointments={appointments}
                  />
                </div>
                <div className="personal-info" id="step2">
                  <Step2
                    onClick={handleProgress}
                    state={state}
                    saving={saving}
                    selectedType={selectedType}
                    message={message}
                    setState={setState}
                    setMessage={setMessage}
                    handleChange={handleChange}
                    setSaving={setSaving}
                    saveAppointment={saveAppointment}
                  />
                </div>
                <div className="confirmation" id="step3">
                  <Step3
                    state={state}
                    meeting={meeting}
                    appointments={appointments}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
